<template>
  <main>
    <!-- section-->
    <div class="mt-4">
      <div class="container">
        <!-- row -->
        <div class="row">
          <!-- col -->
          <div class="col-12">
            <!-- breadcrumb -->
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb mb-0">
                <li class="breadcrumb-item"><a href="/">หน้าหลัก</a></li>
                <li class="breadcrumb-item" v-if="category">
                  <a :href="'/search/' + category">{{ category }}</a>
                </li>
                <li class="breadcrumb-item" v-if="sub">
                  <a :href="'/search/' + category + '/' + sub">{{ sub }}</a>
                </li>
                <!-- <li class="breadcrumb-item active" aria-current="page">Snacks & Munchies</li> -->
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <!-- section -->
    <div class="mt-8 mb-lg-14 mb-8">
      <!-- container -->
      <div class="container">
        <!-- row -->
        <div class="row gx-10">
          <!-- col -->
          <aside class="col-lg-3 col-md-4 mb-6 mb-md-0">
            <div
              class="offcanvas offcanvas-start offcanvas-collapse w-md-50"
              tabindex="-1"
              id="offcanvasCategory"
              aria-labelledby="offcanvasCategoryLabel"
            >
              <div class="offcanvas-header d-lg-none">
                <h5 class="offcanvas-title" id="offcanvasCategoryLabel">Filter</h5>
                <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
              </div>
              <div class="offcanvas-body ps-lg-2 pt-lg-0">
                <div class="mb-8">
                  <!-- title -->
                  <h5 class="mb-3">หมวดหมู่สินค้า</h5>
                  <!-- nav -->
                  <ul class="nav nav-category" id="categoryCollapseMenu">
                    <li
                      class="nav-item border-bottom w-100 collapsed"
                      data-bs-toggle="collapse"
                      :data-bs-target="'#category' + index"
                      aria-expanded="false"
                      aria-controls="categoryFlushOne"
                      v-for="(item, index) in categoryList"
                    >
                      <a href="#" class="nav-link"
                        >{{ item.product_category_name }} <i class="feather-icon icon-chevron-right"></i
                      ></a>

                      <div :id="'category' + index" class="accordion-collapse collapse">
                        <div>
                          <!-- nav -->

                          <ul class="nav flex-column ms-3">
                            <li class="nav-item" v-for="(itemSub, indexSub) in item.category_sub">
                              <a
                                :href="'/search/' + item.product_category_name + '/' + itemSub.product_category_sub_name"
                                class="nav-link"
                                >{{ itemSub.product_category_sub_name }}</a
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>

                <div class="mb-8 d-none">
                  <h5 class="mb-3">Stores</h5>
                  <div class="my-4">
                    <!-- input -->
                    <input type="search" class="form-control" placeholder="Search by store" />
                  </div>
                  <!-- form check -->
                  <div class="form-check mb-2">
                    <!-- input -->
                    <input class="form-check-input" type="checkbox" value="" id="eGrocery" checked />
                    <label class="form-check-label" for="eGrocery"> E-Grocery </label>
                  </div>
                  <!-- form check -->
                  <div class="form-check mb-2">
                    <!-- input -->
                    <input class="form-check-input" type="checkbox" value="" id="DealShare" />
                    <label class="form-check-label" for="DealShare"> DealShare </label>
                  </div>
                  <!-- form check -->
                  <div class="form-check mb-2">
                    <!-- input -->
                    <input class="form-check-input" type="checkbox" value="" id="Dmart" />
                    <label class="form-check-label" for="Dmart"> DMart </label>
                  </div>
                  <!-- form check -->
                  <div class="form-check mb-2">
                    <!-- input -->
                    <input class="form-check-input" type="checkbox" value="" id="Blinkit" />
                    <label class="form-check-label" for="Blinkit"> Blinkit </label>
                  </div>
                  <!-- form check -->
                  <div class="form-check mb-2">
                    <!-- input -->
                    <input class="form-check-input" type="checkbox" value="" id="BigBasket" />
                    <label class="form-check-label" for="BigBasket"> BigBasket </label>
                  </div>
                  <!-- form check -->
                  <div class="form-check mb-2">
                    <!-- input -->
                    <input class="form-check-input" type="checkbox" value="" id="StoreFront" />
                    <label class="form-check-label" for="StoreFront"> StoreFront </label>
                  </div>
                  <!-- form check -->
                  <div class="form-check mb-2">
                    <!-- input -->
                    <input class="form-check-input" type="checkbox" value="" id="Spencers" />
                    <label class="form-check-label" for="Spencers"> Spencers </label>
                  </div>
                  <!-- form check -->
                  <div class="form-check mb-2">
                    <!-- input -->
                    <input class="form-check-input" type="checkbox" value="" id="onlineGrocery" />
                    <label class="form-check-label" for="onlineGrocery"> Online Grocery </label>
                  </div>
                </div>
                <div class="mb-8 d-none">
                  <!-- price -->
                  <h5 class="mb-3">Price</h5>
                  <div>
                    <!-- range -->
                    <div id="priceRange" class="mb-3"></div>
                    <small class="text-muted">Price:</small> <span id="priceRange-value" class="small"></span>
                  </div>
                </div>
                <!-- rating -->
                <div class="mb-8 d-none">
                  <h5 class="mb-3">Rating</h5>
                  <div>
                    <!-- form check -->
                    <div class="form-check mb-2">
                      <!-- input -->
                      <input class="form-check-input" type="checkbox" value="" id="ratingFive" />
                      <label class="form-check-label" for="ratingFive">
                        <i class="bi bi-star-fill text-warning"></i>
                        <i class="bi bi-star-fill text-warning"></i>
                        <i class="bi bi-star-fill text-warning"></i>
                        <i class="bi bi-star-fill text-warning"></i>
                        <i class="bi bi-star-fill text-warning"></i>
                      </label>
                    </div>
                    <!-- form check -->
                    <div class="form-check mb-2">
                      <!-- input -->
                      <input class="form-check-input" type="checkbox" value="" id="ratingFour" checked />
                      <label class="form-check-label" for="ratingFour">
                        <i class="bi bi-star-fill text-warning"></i>
                        <i class="bi bi-star-fill text-warning"></i>
                        <i class="bi bi-star-fill text-warning"></i>
                        <i class="bi bi-star-fill text-warning"></i>
                        <i class="bi bi-star text-warning"></i>
                      </label>
                    </div>
                    <!-- form check -->
                    <div class="form-check mb-2">
                      <!-- input -->
                      <input class="form-check-input" type="checkbox" value="" id="ratingThree" />
                      <label class="form-check-label" for="ratingThree">
                        <i class="bi bi-star-fill text-warning"></i>
                        <i class="bi bi-star-fill text-warning"></i>
                        <i class="bi bi-star-fill text-warning"></i>
                        <i class="bi bi-star text-warning"></i>
                        <i class="bi bi-star text-warning"></i>
                      </label>
                    </div>
                    <!-- form check -->
                    <div class="form-check mb-2">
                      <!-- input -->
                      <input class="form-check-input" type="checkbox" value="" id="ratingTwo" />
                      <label class="form-check-label" for="ratingTwo">
                        <i class="bi bi-star-fill text-warning"></i>
                        <i class="bi bi-star-fill text-warning"></i>
                        <i class="bi bi-star text-warning"></i>
                        <i class="bi bi-star text-warning"></i>
                        <i class="bi bi-star text-warning"></i>
                      </label>
                    </div>
                    <!-- form check -->
                    <div class="form-check mb-2">
                      <!-- input -->
                      <input class="form-check-input" type="checkbox" value="" id="ratingOne" />
                      <label class="form-check-label" for="ratingOne">
                        <i class="bi bi-star-fill text-warning"></i>
                        <i class="bi bi-star text-warning"></i>
                        <i class="bi bi-star text-warning"></i>
                        <i class="bi bi-star text-warning"></i>
                        <i class="bi bi-star text-warning"></i>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="mb-8 position-relative d-none">
                  <!-- Banner Design -->
                  <!-- Banner Content -->
                  <div class="position-absolute p-5 py-8">
                    <h3 class="mb-0">Fresh Fruits</h3>
                    <p>Get Upto 25% Off</p>
                    <a href="#" class="btn btn-dark">Shop Now<i class="feather-icon icon-arrow-right ms-1"></i></a>
                  </div>
                  <!-- Banner Content -->
                  <!-- Banner Image -->
                  <!-- img --><img
                    src="../assets/images/banner/assortment-citrus-fruits.png"
                    alt=""
                    class="img-fluid rounded-3"
                  />
                  <!-- Banner Image -->
                </div>
              </div>
            </div>
          </aside>
          <section class="col-lg-9 col-md-12">
            <!-- card -->
            <div class="card mb-4 bg-light border-0">
              <!-- card body -->
              <div class="card-body p-9">
                <h4 class="mb-0 fs-1">
                  <span v-if="!sub">{{ category }}</span> <span v-if="sub">{{ sub }}</span>
                </h4>
              </div>
            </div>
            <!-- list icon -->
            <div class="d-lg-flex justify-content-between align-items-center">
              <div class="mb-3 mb-lg-0">
                <p class="mb-0">
                  <span class="text-dark">{{ products.length }} </span> Products found
                </p>
              </div>
              <!-- icon -->
              <div class="d-md-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="d-none">
                    <a href="shop-list.html" class="text-muted me-3"><i class="bi bi-list-ul"></i></a>
                    <a href="shop-grid.html" class="me-3 active"><i class="bi bi-grid"></i></a>
                    <a href="shop-grid-3-column.html" class="me-3 text-muted"><i class="bi bi-grid-3x3-gap"></i></a>
                  </div>
                  <div class="ms-2 d-lg-none">
                    <a
                      class="btn btn-outline-gray-400 text-muted"
                      data-bs-toggle="offcanvas"
                      href="#offcanvasCategory"
                      role="button"
                      aria-controls="offcanvasCategory"
                      ><svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-filter me-2"
                      >
                        <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
                      </svg>
                      Filters</a
                    >
                  </div>
                </div>

                <div class="d-flex mt-2 mt-lg-0">
                  <div class="me-2 flex-grow-1">
                    <!-- select option -->
                    <select class="form-select" aria-label="Default select example">
                      <option selected>Show: 50</option>
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                    </select>
                  </div>
                  <div>
                    <!-- select option -->
                    <select class="form-select" aria-label="Default select example">
                      <option selected>Sort by: Featured</option>
                      <option value="Low to High">Price: Low to High</option>
                      <option value="High to Low">Price: High to Low</option>
                      <option value="Release Date">Release Date</option>
                      <option value="Avg. Rating">Avg. Rating</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <!-- row -->
            <div class="row g-4 row-cols-xl-4 row-cols-lg-3 row-cols-2 row-cols-md-2 mt-2">
              <!-- col -->

              <div class="col" v-for="(item, index) in products">
                <!-- card -->
                <div class="card card-product">
                  <div class="card-body">
                    <!-- badge -->
                    <div class="text-center position-relative">
                      <a href="#"
                        ><img :src="this.domain + 'images/' + item.image_main" :alt="item.product_name" class="mb-3 img-fluid"
                      /></a>
                      <!-- action btn -->
                      <div class="card-product-action">
                        <a href="#!" class="btn-action" data-bs-toggle="modal" data-bs-target="#quickViewModal"
                          ><i class="bi bi-eye" data-bs-toggle="tooltip" data-bs-html="true" title="Quick View"></i
                        ></a>
                        <a href="#!" class="btn-action" data-bs-toggle="tooltip" data-bs-html="true" title="Wishlist"
                          ><i class="bi bi-heart"></i
                        ></a>
                        <a href="#!" class="btn-action" data-bs-toggle="tooltip" data-bs-html="true" title="Compare"
                          ><i class="bi bi-arrow-left-right"></i
                        ></a>
                      </div>
                    </div>
                    <!-- heading -->
                    <div class="text-small mb-1" v-if="item.category">
                      <a :href="'/search/' + item.category.product_category_name" class="text-decoration-none text-muted"
                        ><small>{{ item.category.product_category_name }}</small></a
                      >
                    </div>
                    <h2 class="fs-6">
                      <a :href="'/single/' + item.id" class="text-inherit text-decoration-none">{{ item.product_name }}</a>
                    </h2>
                    <div class="text-warning d-none">
                      <!-- rating -->
                      <small>
                        <i class="bi bi-star-fill"></i>
                        <i class="bi bi-star-fill"></i>
                        <i class="bi bi-star-fill"></i>
                        <i class="bi bi-star-fill"></i>
                        <i class="bi bi-star-fill"></i
                      ></small>
                      <span class="text-muted small">5 (469)</span>
                    </div>
                    <!-- price -->
                    <div class="d-flex justify-content-between align-items-center mt-3">
                      <div>
                        <span class="text-dark">฿{{ item.price_sale }}</span>
                        <span class="text-decoration-line-through text-muted">฿{{ item.price }}</span>
                      </div>
                      <!-- btn -->
                      <div>
                        <a href="#!" class="btn btn-primary btn-sm" @click="addCart(item.id, item.price)">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-plus"
                          >
                            <line x1="12" y1="5" x2="12" y2="19"></line>
                            <line x1="5" y1="12" x2="19" y2="12"></line>
                          </svg>
                          Add</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-8">
              <div class="col">
                <!-- nav -->
                <nav>
                  <ul class="pagination">
                    <li class="page-item disabled">
                      <a class="page-link mx-1 rounded-3" href="#" aria-label="Previous">
                        <i class="feather-icon icon-chevron-left"></i>
                      </a>
                    </li>
                    <li class="page-item"><a class="page-link mx-1 rounded-3 active" href="#">1</a></li>
                    <li class="page-item"><a class="page-link mx-1 rounded-3 text-body" href="#">2</a></li>

                    <li class="page-item"><a class="page-link mx-1 rounded-3 text-body" href="#">...</a></li>
                    <li class="page-item"><a class="page-link mx-1 rounded-3 text-body" href="#">12</a></li>
                    <li class="page-item">
                      <a class="page-link mx-1 rounded-3 text-body" href="#" aria-label="Next">
                        <i class="feather-icon icon-chevron-right"></i>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import axios from 'axios'
import { defineComponent } from 'vue'
import { useCartStore } from '@/stores/cart'

export default defineComponent({
  setup() {
    const counter = useCartStore()
    return {
      counter
    }
  },
  inject: ['url_api', 'url_domain_api'],
  data() {
    return {
      domain: this.url_domain_api,
      search: '',
      category: this.$route.params.category,
      sub: this.$route.params.sub,
      products: [],
      categoryList: [],
      user: []
    }
  },
  mounted() {
    this.getData()
    this.getListCategory()
    //if (localStorage.getItem('token')) {
    this.getProfile()
    //}
  },
  methods: {
    async getProfile() {
      await axios.get('/profile', {}).then((response) => {
        if (response) {
          this.user = response.data.user;
        }
      })
    },
    async getData() {
      await axios
        .post('/product-search', {
          category: this.category,
          sub: this.sub,
          search: this.search
        })
        .then((response) => {
          this.products = response.data.product
        })
    },
    async getListCategory() {
      await axios.get('/product-category', {}).then((response) => {
        this.categoryList = response.data
      })
    },
    async addCart(id, price) {
      if(this.user.id) {
        await axios
          .post('/cart', {
            product_id: id,
            user_id: this.user.id,
            status: 0,
            amount: 1,
            price: price
          })
          .then((response) => {
            this.counter.cart++
          })
      } else {
        this.$router.push({ path: '/login' }).then(() => {
            this.$router.go()
          })
      }
    }
  }
})
</script>
